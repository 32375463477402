<template>
  <div class="mb-2">
    <vSelect
      class="custom-vue-select"
      :options="options"
      :disabled="disabled ? disabled : false"
      :clearable="clearable ? clearable : false"
      :value="value"
      :reduce="options => (reduceKey ? options[reduceKey] : options.id)"
      label="name"
      @input="setSelected"
      @option:selected="$emit('option:selected', $event)"
      @search:blur="$emit('search-blur')"
      v-bind="$attrs"
    ></vSelect>
    <span v-if="validateState === false" class="text-danger">
      {{ $t("VALIDATION.INVALID", { name: "" }) }}
    </span>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "FormSelect",
  inheritAttrs: false,
  props: {
    /**
     * All options existing in the select options
     */
    options: {
      type: Array,
      required: true
    },
    /**
     * Disabled True/False
     */
    disabled: Boolean,
    /**
     * Clearable True/False
     */
    clearable: Boolean,
    /**
     * V-Model
     */
    value: {},
    /**
     * what the Select returns e.g. ID
     */
    reduceKey: String,
    /**
     * Validation id of the error
     */
    feedback_id: {
      type: String,
      default: ""
    },
    /**
     * All Translation i18n keys used
     */
    i18n: {
      type: Object,
      default: () => ({})
    },
    /**
     * All validations rules and params
     */
    validations: {
      type: Object,
      default: () => ({})
    },
    /**
     * Validate State validation
     */
    validateState: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {};
  },
  components: {
    vSelect
  },
  mounted() {},
  computed: {},
  methods: {
    setSelected(value) {
      //  trigger a mutation, or dispatch an action
      this.$emit("input", value);
    }
  }
};
</script>

<style>
.custom-vue-select .vs__dropdown-toggle {
  border: 1px solid #e5eaee;
}

.custom-vue-select .vs__selected {
  color: #464e5f;
  font-size: 1rem;
  font-weight: 400;
}

.custom-vue-select .vs__search {
  color: #464e5f;
  font-size: 1rem;
  font-weight: 400;
}

.custom-vue-select .vs__search::placeholder {
  color: #b5b5c3;
}
</style>
